import { getFirebaseBackend } from '../../helpers/firebase/authUtils'
import AuthServiceApi from "../../service/api/auth"
export const state = {
    token: sessionStorage.getItem('token'),
    user: sessionStorage.getItem('user')
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.token = newValue.token;
        state.user = newValue.user;
        saveState('token', newValue.token)
        saveState('user', newValue.user)
    },
    RM_TOKEN(){
        state.token = null;
        state.user = null, 
        removeState('token');
        removeState('user');
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    logIn({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')
        return AuthServiceApi.login(email, password).then((response) => {
             const user = response.data;
            commit('SET_CURRENT_USER', user)
            return response
        });
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('RM_TOKEN')
    },

    // register the user
    register({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().registerUser(email, password).then((response) => {
            const user = response
            commit('SET_CURRENT_USER', user)
            return user
        });
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().forgetPassword(email).then((response) => {
            const message = response.data
            return message
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)
        const user = getFirebaseBackend().getAuthenticatedUser();
        commit('SET_CURRENT_USER', user)
        return user;
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}
function removeState(key) {
    window.localStorage.removeItem(key);
}
